const useImageThumbnail = (url?: string, thumbnail = '64') => {
  const isStorageUrl = url?.includes(import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET);
  return isStorageUrl && thumbnail
    ? url?.includes('?')
      ? url?.replace('?', `@thumb${thumbnail}?`)
      : `${url}@thumb${thumbnail}`
    : url;
};

export default useImageThumbnail;
