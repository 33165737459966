import { useCallback } from 'react';
import { logError } from 'services/logging';

// Helper function to extract the storage path from a Firebase download URL
const extractPathFromUrl = (url: string): string | null => {
  const regex = /\/o\/([^?]+)\?/;
  const match = url.match(regex);
  if (match && match[1]) {
    // Decode the path and replace encoded '/' (%2F) with real '/'
    return decodeURIComponent(match[1]).replace(/%2F/g, '/');
  }
  return null;
};

const useHandleFileDownload = (onIsLoading: (isLoading: boolean) => void) => {
  const handleFileDownload = useCallback(
    async (urls: string) => {
      onIsLoading(true);
      const urlArray = urls.split(';');

      for (const url of urlArray) {
        const extractedURL = extractPathFromUrl(url) ?? '';

        // Extract filename and extension from the extracted path
        const filename = extractedURL.split('/').pop() || 'downloaded_file'; // Get the filename from the path

        try {
          // Fetch the file as a Blob using the URL (no need to get metadata)
          const response = await fetch(url); // Use the download URL directly
          const blob = await response.blob();

          // Create a Blob URL
          const blobUrl = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', filename);

          document.body.appendChild(link);
          link.click();

          // Clean up
          document.body.removeChild(link);
          URL.revokeObjectURL(blobUrl); // Release the Blob URL
        } catch (error) {
          logError(error);
        }
      }

      onIsLoading(false);
    },
    [onIsLoading],
  );

  return handleFileDownload;
};

export default useHandleFileDownload;
