import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { IconContext } from 'react-icons/lib';
import { ProductsProvider } from 'screens/Products/useProducts';
import { BuyersMarketProvider } from 'hooks/useBuyersMarket';
import { BuyersMarketCartProvider } from 'hooks/useBuyersMarketCart';
import BottomNav from './BottomNav';
import Header from './Header';

const Navigation = ({ children }: { children: ReactNode }) => {
  return (
    <ProductsProvider>
      <BuyersMarketCartProvider>
        <BuyersMarketProvider>
          <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
            <Header />
            <Box
              id="bodyContent"
              sx={(theme) => ({
                touchAction: 'pan-x pan-y',
                marginTop: theme.spacing(8),
                [theme.breakpoints.down('md')]: { marginTop: 0 },
              })}
            >
              {children}
            </Box>
            <BottomNav />
          </IconContext.Provider>
        </BuyersMarketProvider>
      </BuyersMarketCartProvider>
    </ProductsProvider>
  );
};

export default Navigation;
