import { Alert, Snackbar, SnackbarContent, SnackbarProps } from '@mui/material';
import React, { useState, useCallback, createContext, useContext } from 'react';
import ErrorSnackbar from 'components/ErrorSnackbar';

export enum SnackbarVariant {
  DEFAULT = 'default',
  ERROR = 'error',
  ALERT = 'alert',
}
const SnackbarContext = createContext<
  { showSnackbar: (props: SnackbarProps, variant?: SnackbarVariant) => void; hideSnackbar: () => void } | undefined
>(undefined);

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps | null>(null);
  const [variant, setVariant] = useState<SnackbarVariant>(SnackbarVariant.DEFAULT);

  const showSnackbar = useCallback((props: SnackbarProps, variant = SnackbarVariant.DEFAULT) => {
    setSnackbarProps(props);
    setOpen(true);
    setVariant(variant);
  }, []);

  const hideSnackbar = useCallback(() => {
    setOpen(false);
    setSnackbarProps(null);
  }, []);

  const handleClose = useCallback(() => {
    hideSnackbar();
  }, [hideSnackbar]);

  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      {children}
      {variant === SnackbarVariant.ERROR ? (
        <ErrorSnackbar isOpen={open} onClose={handleClose} children={snackbarProps?.message?.toString()} />
      ) : (
        <Snackbar
          open={open}
          onClose={handleClose}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          autoHideDuration={snackbarProps?.autoHideDuration}
        >
          {variant === SnackbarVariant.ALERT ? (
            <Alert
              severity={snackbarProps?.color === 'error' ? 'error' : 'info'}
              onClose={handleClose}
              sx={{ width: '100%' }}
            >
              {snackbarProps?.message}
            </Alert>
          ) : (
            <SnackbarContent
              sx={{
                textAlign: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
              message={snackbarProps?.message}
            />
          )}
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
