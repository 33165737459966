import { Box, Button, Stack } from '@mui/material';
import { ElementRef, useCallback, useRef, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { MdFileUpload } from 'react-icons/md';
import useDebouncedValue from 'hooks/useDebouncedValue';
import useFileCompression from 'hooks/useFileCompression';
import ImageUploadDialog from './ImageUploadDialog';
import ImageUploadLoader from './ImageUploadLoader';

const DEBOUNCE_TIME = 700;

const DEFAULT_FIELD_SIZE = '160px';
const IMAGE_SIZE = '30px';

const ACCEPTED_FILE_TYPES = 'image/*, application/pdf';

export interface FileMetaData {
  name?: string;
  size?: string;
}

const ImageUploadField = ({
  label = '',
  hasWhiteFields = false,
  files = [],
  relatedField,
  onChange,
}: {
  label?: string;
  hasWhiteFields?: boolean;
  files?: string[];
  relatedField?: string; // Value from previous field
  onChange: (urls: string[]) => void;
}) => {
  const hasUploadFile = files && files?.length > 0;
  const [open, setOpen] = useState(false);
  const [compressedFile, compressFile] = useFileCompression();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<ElementRef<'input'>>(null);
  const debouncedRelatedField = useDebouncedValue(relatedField, DEBOUNCE_TIME);

  const handleUploadClick = () => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.click();
  };

  const handleFileUpload = async (event: any) => {
    if (!event.target.files || !event.target.files.length) {
      compressFile(null);
      return;
    }
    setLoading(true);
    compressFile(event.target.files[0]);
  };

  const handleUploadComplete = useCallback(
    (url: string) => {
      setLoading(false);
      onChange([...files, url]);
      compressFile(null);
    },
    [files, onChange, compressFile],
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = (index: number) => {
    onChange([...files.filter((_, srcIndex) => srcIndex !== index)]);
  };

  return (
    <Box sx={(theme) => ({ margin: theme.spacing(1, 0, 0.5) })}>
      <input
        accept={ACCEPTED_FILE_TYPES}
        type="file"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        ref={inputRef}
      />
      <Button
        color="primary"
        variant={'outlined'}
        disabled={loading}
        sx={(theme) => ({
          width: DEFAULT_FIELD_SIZE,
          height: 40,
          padding: hasUploadFile ? 0 : theme.spacing(1),
          background: hasWhiteFields ? theme.palette.background.paper : 'initial',
        })}
        onClick={hasUploadFile ? handleOpen : handleUploadClick}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={(theme) => ({
            paddingX: hasUploadFile ? theme.spacing(1) : 0,
            width: '100%',
          })}
        >
          {label}
          {loading && compressedFile ? (
            <ImageUploadLoader
              small
              rawFile={compressedFile}
              onComplete={handleUploadComplete}
              label={label}
              files={files}
            />
          ) : hasUploadFile ? (
            <Stack direction={'row'} sx={{ alignItems: 'center' }} gap={1}>
              <img src={files[0]} alt="" width={IMAGE_SIZE} height={IMAGE_SIZE} style={{ borderRadius: 8 }} />
              <FaPlus />
            </Stack>
          ) : (
            <Stack width={IMAGE_SIZE} height={IMAGE_SIZE} alignItems={'center'} justifyContent={'center'}>
              <MdFileUpload size={16} />
            </Stack>
          )}
        </Stack>
      </Button>
      <ImageUploadDialog
        files={files}
        open={open}
        loading={loading}
        compressedFile={compressedFile}
        label={label}
        reference={debouncedRelatedField}
        onRemove={handleRemove}
        onClose={handleClose}
        onFileUpload={handleFileUpload}
        onUploadComplete={handleUploadComplete}
      />
    </Box>
  );
};

export default ImageUploadField;
