import { Container, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EDIT_SHIPMENT_CODE } from 'services/api-constants';
import { getPrefixedRoute } from 'services/theming';
import { OperationContext } from 'urql';
import ExpandableTableNew from 'components/ExpandableTableNew/ExpandableTableNew';
import { FormDialogState } from 'components/FormDialog';
import Hero, { HeroCover } from 'components/Hero';
import LazyFormDialog from 'components/LazyFormDialog';
import LoadingButton from 'components/LoadingButton';
import QueryResult from 'components/QueryResult';
import ShipmentGraphic from 'components/ShipmentGraphic';
import TableDialog, { TableDialogVariant } from 'components/TableDialog';
import { defaultFormDialogState } from 'components/default-form-dialog-state';
import useBackoffInterval from 'hooks/useBackoffInterval';
import { useFeatures } from 'hooks/useFeatures';
import useIsViewport from 'hooks/useIsViewport';
import { useShipments } from 'hooks/useShipments';
import { SnackbarVariant, useSnackbar } from 'hooks/useSnackBar';
import { getFormValuesNew, parseForm } from 'utils/form';
import { getTableFieldValues, updateExpandableTableFields } from 'utils/table';
import {
  EditShipmentIntent,
  useEditShipmentQuery,
  useEditShipmentHasPendingQuery,
  ExpandableTable as ExpandableTableType,
  Form as FormData,
  EditShipmentQuery,
  ShipmentInput,
  ShipmentStatus,
  ActionIntent,
  ShipmentInputNew,
  TableDialogFragment,
  useUpdateShipmentNewMutation,
  useUpdateShipmentMutation,
  FormType,
} from 'generated/graphql';
import EditShipmentInfo from './EditShipmentInfo';

const FIVE_SECONDS = 1000 * 5;

enum TableActionCode {
  InboundAddProduct = 'InboundAddProduct',
  InboundIncrease = 'InboundIncrease',
  InboundDecrease = 'InboundDecrease',
  OutboundAddProduct = 'OutboundAddProduct',
  OutboundMove = 'OutboundMove',
  FinanceAdd = 'FinanceAdd',
  ShipmentAddProductBuyer = 'ShipmentAddProductBuyer',
  AddProductSeller = 'AddProductSeller',
  ShipmentAddProductSeller = 'ShipmentAddProductSeller',
  DirectAddProduct = 'DirectAddProduct',
  ExternalAddProduct = 'ExternalAddProduct',
  MoveRequested = 'MoveRequested',
}

enum PendingState {
  IS_FETCHING = 'IS_FETCHING',
  HAS_PENDING = 'HAS_PENDING',
  DONE = 'DONE',
}

interface GetShipmentInput {
  id: string;
  data: EditShipmentQuery;
  table: ExpandableTableType;
  form: FormData;
  intent: EditShipmentIntent;
  actionIntent?: ActionIntent;
}

const getShipmentInput = async ({
  id,
  data,
  table,
  form,
  intent,
  actionIntent = ActionIntent.Request,
}: GetShipmentInput) => {
  return {
    id,
    status: ShipmentStatus.Unknown,
    type: data.editShipment.type,
    fields: await getFormValuesNew(form),
    received: getTableFieldValues(table, EDIT_SHIPMENT_CODE.Received).map((rowValue) => ({
      id: rowValue.rowId,
      quantity: +rowValue.value,
    })),
    loaded: getTableFieldValues(table, EDIT_SHIPMENT_CODE.Loaded).map((rowValue) => ({
      id: rowValue.rowId,
      quantity: +rowValue.value,
    })),
    quantity: getTableFieldValues(table, EDIT_SHIPMENT_CODE.Quantity).map((rowValue) => ({
      id: rowValue.rowId,
      value: rowValue.value,
    })),
    price: getTableFieldValues(table, EDIT_SHIPMENT_CODE.Price).map((rowValue) => ({
      id: rowValue.rowId,
      value: rowValue.value,
    })),
    adjustment: getTableFieldValues(table, EDIT_SHIPMENT_CODE.Adjustment).map((rowValue) => ({
      id: rowValue.rowId,
      value: rowValue.value,
    })),
    percentage: getTableFieldValues(table, EDIT_SHIPMENT_CODE.Percentage).map((rowValue) => ({
      id: rowValue.rowId,
      value: rowValue.value,
    })),
    innerCasepack: getTableFieldValues(table, EDIT_SHIPMENT_CODE.InnerCasepack).map((rowValue) => ({
      id: rowValue.rowId,
      value: rowValue.value,
    })),
    casepack: getTableFieldValues(table, EDIT_SHIPMENT_CODE.CasePack).map((rowValue) => ({
      id: rowValue.rowId,
      value: rowValue.value,
    })),
    caseType: getTableFieldValues(table, EDIT_SHIPMENT_CODE.CaseType).map((rowValue) => ({
      id: rowValue.rowId,
      value: rowValue.value,
    })),
    volumeAdjustment: getTableFieldValues(table, EDIT_SHIPMENT_CODE.VolumeAdjustment).map((rowValue) => ({
      id: rowValue.rowId,
      value: rowValue.value,
    })),
    blocks: getTableFieldValues(table, EDIT_SHIPMENT_CODE.Block).map((rowValue) => ({
      id: rowValue.rowId,
      value: rowValue.value,
    })),
    handlingFee: getTableFieldValues(table, EDIT_SHIPMENT_CODE.HandlingFee).map((rowValue) => ({
      id: rowValue.rowId,
      value: rowValue.value,
    })),
    intent,
    actionIntent,
  } as ShipmentInput;
};

const getActionButtons = (data?: EditShipmentQuery) => {
  if (!data) return [];
  if (data.editShipment.actionButtons.length > 0 && data.editShipment.actionButtons === null)
    return data.editShipment.actionButtons;
  return [data.editShipment.actionButton];
};

const EditShipmentNew = () => {
  const { isEnabled } = useFeatures();
  const isProductRequestEnabled = isEnabled('ProductRequest');
  const isEditShipmentPolishEnabled = isEnabled('EditShipmentPolish');

  const isMobile = useIsViewport('md');
  const { id, intent } = useParams<{
    id: string;
    intent?: EditShipmentIntent;
  }>();
  const shipmentId = id ?? '';
  const [table, setTable] = useState<ExpandableTableType>();
  const [form, setForm] = useState<FormData>();

  const [queryResult, refetchQueryResult] = useEditShipmentQuery({
    variables: {
      id: shipmentId,
      intent: intent || EditShipmentIntent.Edit,
    },
    pause: !shipmentId,
    requestPolicy: 'cache-and-network',
  });
  const [hasPendingResult, refetchHasPendingResult] = useEditShipmentHasPendingQuery({
    variables: {
      id: shipmentId,
      intent: intent || EditShipmentIntent.Edit,
    },
    pause: !shipmentId,
    requestPolicy: 'network-only',
  });
  const title = queryResult.data?.editShipment.title;
  const [hasPending, setHasPending] = useState(false);
  const [pendingState, setPendingState] = useState<PendingState>(PendingState.DONE);
  const [tableHasEdits, setTableHasEdits] = useState(false);
  const actionButtons = getActionButtons(queryResult.data);
  const [formDialog, setFormDialog] = useState<FormDialogState>(defaultFormDialogState);
  const [resultNew, updateShipmentNew] = useUpdateShipmentNewMutation();
  const [result, updateShipment] = useUpdateShipmentMutation();
  const { showSnackbar, hideSnackbar } = useSnackbar();
  const { onRefetch } = useShipments();
  const urlReference = useRef('');
  const navigate = useNavigate();
  const [postSubmitDialog, setPostSubmitDialog] = useState<TableDialogFragment>();

  useBackoffInterval(refetchHasPendingResult, pendingState !== PendingState.DONE, FIVE_SECONDS, 1);

  const handleTableFieldUpdate = (rowId: string, cellIndex: number, newValue: string) => {
    setTableHasEdits(true);
    setTable((prevTable) => prevTable && updateExpandableTableFields(prevTable, cellIndex, rowId, newValue));
  };

  const handleRefetch = (opts?: Partial<OperationContext> | undefined) => {
    refetchQueryResult(opts);
    refetchHasPendingResult();
  };

  useEffect(() => {
    if (queryResult.data?.editShipment.form) {
      setForm(parseForm(queryResult.data?.editShipment.form as FormData));
    }
  }, [queryResult.data?.editShipment.form]);

  useEffect(() => {
    if (queryResult.data?.editShipment.itemsTable && !tableHasEdits) {
      setTable(queryResult.data?.editShipment.itemsTable as ExpandableTableType);
    }
  }, [queryResult.data?.editShipment.itemsTable, tableHasEdits]);

  useEffect(() => {
    if (!hasPendingResult.fetching && !hasPendingResult?.data?.editShipment?.hasPending) {
      return;
    }

    const resultHasPending = !!hasPendingResult?.data?.editShipment?.hasPending;
    if (pendingState === PendingState.DONE && !hasPending && resultHasPending) {
      setPendingState(PendingState.IS_FETCHING);
    } else if (resultHasPending) {
      setPendingState(PendingState.HAS_PENDING);
    } else if (pendingState === PendingState.HAS_PENDING && !resultHasPending) {
      setPendingState(PendingState.DONE);
    }

    if (resultHasPending !== hasPending) {
      setHasPending(resultHasPending);
    }
  }, [hasPendingResult?.data?.editShipment?.hasPending, pendingState, hasPending, hasPendingResult.fetching]);

  const handleActionButton = async (customIntent?: EditShipmentIntent) => {
    hideSnackbar();

    if (!form || queryResult.fetching || !queryResult.data || !table || !intent) {
      return;
    }

    setFormDialog(defaultFormDialogState);

    const handleError = (errorMessage: string) => {
      showSnackbar({ message: errorMessage }, SnackbarVariant.ERROR);
    };

    const input: ShipmentInput = {
      ...(await getShipmentInput({
        id: shipmentId,
        intent: customIntent || intent,
        data: queryResult.data,
        table,
        form,
      })),
    };

    let response: { redirectUrl?: string; hasDialog?: boolean; dialog?: TableDialogFragment } | undefined;
    let error: string | undefined;
    if (isProductRequestEnabled) {
      const res = await updateShipment({
        input: {
          ...input,
          eTag: queryResult.data.editShipment.eTag,
          VAT: getTableFieldValues(table, EDIT_SHIPMENT_CODE.VAT).map((rowValue) => ({
            id: rowValue.rowId,
            value: rowValue.value,
          })),
          RequestStatuses: getTableFieldValues(table, EDIT_SHIPMENT_CODE.RequestStatuses).map((rowValue) => ({
            id: rowValue.rowId,
            value: rowValue.value,
          })),
          RequestReason: getTableFieldValues(table, EDIT_SHIPMENT_CODE.RequestReason).map((rowValue) => ({
            id: rowValue.rowId,
            value: rowValue.value,
          })),
        },
      });

      error = res.error?.message;
      response = res?.data?.updateShipment;
    } else {
      const inputNew: ShipmentInputNew = {
        ...input,
        eTag: queryResult.data.editShipment.eTag,
        VAT: getTableFieldValues(table, EDIT_SHIPMENT_CODE.VAT).map((rowValue) => ({
          id: rowValue.rowId,
          value: rowValue.value,
        })),
        RequestStatuses: getTableFieldValues(table, EDIT_SHIPMENT_CODE.RequestStatuses).map((rowValue) => ({
          id: rowValue.rowId,
          value: rowValue.value,
        })),
      };
      const res = await updateShipmentNew({
        input: inputNew,
      });

      error = res.error?.message;
      response = res?.data?.updateShipmentNew;
    }

    if (error) {
      handleError(error);
      return;
    }

    if (!response) return;
    onRefetch(); // Refreshes shipments data immediately since updateShipment edits shipments data

    urlReference.current = response.redirectUrl ?? '';

    if (response.hasDialog) {
      setPostSubmitDialog(response.dialog);
      return;
    }

    const url = response.redirectUrl ?? '';
    if (url.length) {
      navigate(getPrefixedRoute(url), { replace: true });
      return;
    }

    setTableHasEdits(false);
    handleRefetch();
  };

  const handleTableActionClick = (code: string, rowIds: string[]) => {
    switch (code) {
      case TableActionCode.InboundAddProduct:
        setFormDialog({
          open: true,
          editIDs: [shipmentId],
          eTags: queryResult.data?.editShipment.eTag,
          type: FormType.AddInboundShipmentProduct,
          addButton: false,
        });
        break;
      case TableActionCode.InboundDecrease:
        setFormDialog({
          open: true,
          editIDs: [shipmentId, ...rowIds],
          eTags: queryResult.data?.editShipment.eTag,
          type: FormType.DecreaseInboundShipmentExpected,
          addButton: false,
        });
        break;
      case TableActionCode.InboundIncrease:
        setFormDialog({
          open: true,
          editIDs: [shipmentId, ...rowIds],
          eTags: queryResult.data?.editShipment.eTag,
          type: FormType.IncreaseInboundShipmentExpected,
          addButton: false,
        });
        break;
      case TableActionCode.OutboundAddProduct:
        setFormDialog({
          open: true,
          editIDs: [shipmentId, ...rowIds],
          eTags: queryResult.data?.editShipment.eTag,
          type: FormType.AddOutboundShipmentProduct,
          addButton: false,
        });
        break;
      case TableActionCode.OutboundMove:
        setFormDialog({
          open: true,
          editIDs: [shipmentId, ...rowIds],
          eTags: queryResult.data?.editShipment.eTag,
          type: FormType.MoveOutboundShipmentInventory,
          addButton: false,
        });
        break;
      case TableActionCode.FinanceAdd:
        setFormDialog({
          open: true,
          editIDs: [shipmentId, ...rowIds],
          eTags: queryResult.data?.editShipment.eTag,
          type: FormType.ShipmentFinanceAddItem,
          addButton: false,
        });
        break;
      case TableActionCode.ShipmentAddProductBuyer:
        setFormDialog({
          open: true,
          editIDs: [shipmentId],
          eTags: queryResult.data?.editShipment.eTag,
          type: FormType.AddOutboundShipmentProductBuyer,
          addButton: false,
        });
        break;
      case TableActionCode.ShipmentAddProductSeller:
        setFormDialog({
          open: true,
          editIDs: [shipmentId],
          eTags: queryResult.data?.editShipment.eTag,
          type: FormType.AddProductsSeller,
          addButton: false,
        });
        break;
      case TableActionCode.DirectAddProduct:
        setFormDialog({
          open: true,
          editIDs: [shipmentId],
          eTags: queryResult.data?.editShipment.eTag,
          type: FormType.AddDirectShipmentProduct,
          addButton: false,
        });
        break;
      case TableActionCode.ExternalAddProduct:
        setFormDialog({
          open: true,
          editIDs: [shipmentId],
          eTags: queryResult.data?.editShipment.eTag,
          type: FormType.AddExternalShipmentProduct,
          addButton: false,
        });
        break;
      case TableActionCode.MoveRequested:
        if (isProductRequestEnabled) {
          setFormDialog({
            open: true,
            editIDs: [shipmentId, ...rowIds],
            eTags: queryResult.data?.editShipment.eTag,
            type: FormType.MoveRequested,
            addButton: false,
          });
        }
        break;
    }
  };

  const showEditShipmentInfo = !isEditShipmentPolishEnabled || (isEditShipmentPolishEnabled && form);
  const [hasTableScroll, setHasTableScroll] = useState(false);

  return (
    <QueryResult result={queryResult} empty="Could not load data">
      <Container>
        <Hero title={isMobile ? '' : (title ?? 'Edit shipment')} cover={HeroCover.Map} isReturn={isMobile} />
        <Grid
          container
          gap={isMobile ? 0 : 1}
          justifyContent={'space-between'}
          sx={(theme) => ({
            flexDirection: isEditShipmentPolishEnabled && hasTableScroll ? 'column-reverse' : 'initial',
            [theme.breakpoints.down('md')]: {
              width: '100vw',
              left: 0,
              position: 'absolute',
              flexDirection: 'column-reverse',
              paddingBottom: '100px', // Padding to avoid table hiding behind the action buttons and navbar on mobile
            },
          })}
        >
          <Grid
            size={{ xs: 12, md: showEditShipmentInfo && !hasTableScroll ? 7.8 : 12 }}
            sx={(theme) => ({ padding: theme.spacing(0, 0, 1) })}
          >
            {!!table && (
              <ExpandableTableNew
                table={table}
                hasPending={hasPending}
                onActionClick={handleTableActionClick}
                onFieldUpdate={handleTableFieldUpdate}
                onRefresh={() => {
                  handleRefetch({ requestPolicy: 'network-only' });
                }}
                setHasScroll={setHasTableScroll}
              />
            )}
          </Grid>
          {showEditShipmentInfo && (
            <Grid
              size={{ xs: 12, md: isEditShipmentPolishEnabled && hasTableScroll ? 12 : 4 }}
              sx={(theme) => ({
                paddingY: theme.spacing(1),
                [theme.breakpoints.down('md')]: { padding: 0 },
              })}
            >
              <EditShipmentInfo
                form={form}
                actionButtons={actionButtons}
                disableActionButtons={result.fetching || resultNew.fetching || hasPending}
                submitLoading={result.fetching || resultNew.fetching}
                onSubmit={handleActionButton}
                setForm={setForm}
                onRefetch={handleRefetch}
                hideActionButtons={isEditShipmentPolishEnabled && hasTableScroll}
              />
            </Grid>
          )}
        </Grid>
        {(isMobile || !showEditShipmentInfo || (isEditShipmentPolishEnabled && hasTableScroll)) && (
          <Stack
            direction={'row'}
            sx={
              isMobile
                ? (theme) => ({
                    gap: theme.spacing(1),
                    position: 'fixed',
                    bottom: 50,
                    width: '100vw',
                    left: 0,
                    background: theme.palette.background.paper,
                    padding: theme.spacing(1.5),
                    boxShadow: '1px 1px 5px rgba(0,0,0,0.4)',
                    zIndex: 5,
                  })
                : (theme) => ({
                    display: 'flex',
                    gap: theme.spacing(1),
                    padding: theme.spacing(1),
                    justifyContent: 'end',
                  })
            }
          >
            {isMobile && (
              <LoadingButton
                variant="text"
                onClick={() => {
                  navigate(getPrefixedRoute('/shipments'));
                }}
                disabled={result.fetching || resultNew.fetching || hasPending}
                loading={result.fetching || resultNew.fetching}
                fullWidth
              >
                Cancel
              </LoadingButton>
            )}
            {actionButtons.map((button, index) => {
              if (!button) return null;
              return (
                <LoadingButton
                  fullWidth={isMobile}
                  key={index}
                  sx={(theme) => ({
                    [theme.breakpoints.up('md')]: {
                      width: 200,
                    },
                  })}
                  color={(button.color?.toLowerCase() as any) || 'secondary'}
                  variant={(button.variant?.toLowerCase() as any) || 'contained'}
                  disabled={result.fetching || resultNew.fetching || hasPending}
                  loading={result.fetching || resultNew.fetching}
                  onClick={() => handleActionButton(button.actions?.[0]?.code as EditShipmentIntent | undefined)}
                >
                  {button.value || 'Save'}
                </LoadingButton>
              );
            })}
          </Stack>
        )}
        {formDialog.open && (
          <LazyFormDialog
            type={formDialog.type}
            editIDs={formDialog.editIDs}
            open={formDialog.open}
            addButton={formDialog.addButton}
            onClose={(success?: boolean) => {
              setFormDialog(defaultFormDialogState);
              if (success) {
                handleRefetch();
              }
            }}
          />
        )}
      </Container>
      <TableDialog
        data={postSubmitDialog}
        variant={TableDialogVariant.Detailed}
        graphic={<ShipmentGraphic />}
        onClose={() => setPostSubmitDialog(undefined)}
      />
    </QueryResult>
  );
};

export default EditShipmentNew;
