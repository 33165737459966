import { Route, Routes, BrowserRouter } from 'react-router-dom';
import useSyncPushToken from 'screens/Auth/useSyncPushToken';
import AuthCheck from 'components/AuthCheck';
import SubRoutes from './SubRoutes';

const Router = () => {
  useSyncPushToken();
  return (
    <AuthCheck>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<SubRoutes />}></Route>
        </Routes>
      </BrowserRouter>
    </AuthCheck>
  );
};

export default Router;
