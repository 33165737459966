import { Dayjs } from 'dayjs';
import { Draft } from 'immer';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'services/dayjs';
import { CombinedError, RequestPolicy } from 'urql';
import { useWhiteLabelling } from 'components/WhiteLabellingProvider';
import useIsViewport from 'hooks/useIsViewport';
import useSearch from 'hooks/useSearch';
import { useStitchProducts } from 'hooks/useStitchProducts';
import useUser, { UserType } from 'hooks/useUser';
import { DEFAULT_PAGE_NUMBER } from 'utils/constants';
import {
  Card,
  DeliveryDate,
  NoticeDialog,
  PageInfo,
  ProductData,
  ProductSection,
  ProductsQuery,
  ProductsScreen,
  ProductTypeSection,
  RefetchType,
  SelectOption,
  useProductsMetaQuery,
  useProductsQuery,
  useSubmitPriceMutation,
  useSubmitSupplyOnDateMutation,
} from 'generated/graphql';
import { getDayOfWeek } from './getDayOfWeek';

export interface SubmitPrice {
  sku: string;
  location: string;
  amount: number;
}

export interface SubmitSupply {
  productID: string;
  deliveryDate: DeliveryDate;
  maxCases: number;
}

const DEFAULT_LOCATION_ID = '0';
const DAYS_MOBILE = 2;
const DAYS_DESKTOP = 7;
const DEFAULT_PAGE_SIZE = 25;
const REQUEST_DAYS = 21; //multiple of DAYS_DESKTOP
const REQUEST_DAYS_MOBILE = 16; //multiple of DAYS_MOBILE

const Context = createContext<{
  isFetching: boolean;
  error: Error | undefined;
  info?: Card[];
  noticeDialog?: NoticeDialog;
  locationOptions?: SelectOption[];
  weekdays?: string[];
  suggestions?: string[];
  pageInfo?: PageInfo;
  productTypes?: ProductTypeSection[];
  rangeStepperOptions: { title: string; subtitle: string }[];
  rangeStepperOptionsInputs: { title: string; subtitle: string }[];
  pricesBarOptions: string[];
  screenSizeDays: number;
  isUpdateDirty: boolean;
  isNoData: boolean;
  searchValue: string;
  startDate: dayjs.Dayjs;
  sellerID?: string;
  pricingLocationID?: string | null;
  isEmpty?: boolean;
  totalProducts: number;
  dateIndex: number;
  onSearchChange: (value: string) => void;
  onStartDateChange: (date: dayjs.Dayjs) => void;
  onPricingLocationIDChange: (locationID: string) => void;
  onRefetch: (requestPolicy?: RequestPolicy, ids?: string[], refetchType?: RefetchType) => void;
  onDataChange: (updater: (draft: Draft<ProductsQuery['products']['products']> | undefined) => void) => void;
  onSubmitPrice: (submitPrice: SubmitPrice) => Promise<undefined | CombinedError>;
  onSubmitSupply: (submitSupply: SubmitSupply) => Promise<undefined | CombinedError>;
  onResetPage: () => void;
  onIncreasePage: () => void;
} | null>(null);

export const ProductsProvider = ({ children }: { children: ReactNode }) => {
  const [isUpdateDirty, setUpdateDirty] = useState(false);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const { user, isAdmin, isPendingReview, isRegistering } = useUser();
  const [getParam] = useSearch();
  const profileIDParam = getParam('profile');
  const sellerID = isAdmin && profileIDParam ? profileIDParam : user?.profileId?.toString();
  const { isInputsProfile, isVillaProfile } = useWhiteLabelling();
  const [searchValue, setSearchValue] = useState(getParam('productType') ?? '');
  const [startDate, setStartDate] = useState(() => {
    const todayDate = dayjs();
    const daysFromMonday = getDayOfWeek(todayDate);
    const thisWeekMonday = todayDate.subtract(daysFromMonday, 'day');
    const newStartDate = isInputsProfile ? thisWeekMonday : todayDate;
    const timezoneOffset = newStartDate.utcOffset();
    return newStartDate.add(timezoneOffset, 'minute');
  });
  const [pricingLocationID, setPricingLocationID] = useState<string | null>(null);
  const isMobile = useIsViewport();
  const requestDays = isMobile ? REQUEST_DAYS_MOBILE : REQUEST_DAYS;
  const screenSizeDays = isInputsProfile || isVillaProfile ? 1 : isMobile ? DAYS_MOBILE : DAYS_DESKTOP;

  const queryStartDate = useMemo(() => {
    const utcStartDate = new Date(
      Date.UTC(startDate.get('year'), startDate.get('month'), startDate.get('date'), 0, 0, 0),
    );
    return utcStartDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
  }, [startDate]);

  const isProductsQueryPaused =
    UserType.Buyer === user?.userType || !sellerID || isPendingReview || isRegistering || !user?.profileId;

  const variables = useMemo(
    () => ({
      sellerID: sellerID!,
      startDate: queryStartDate,
      days: REQUEST_DAYS,
      search: searchValue,
      locationID: pricingLocationID ?? DEFAULT_LOCATION_ID,
      paginationOption: {
        pageNumber: pageNumber,
        pageSize: DEFAULT_PAGE_SIZE,
      },
    }),
    [sellerID, queryStartDate, searchValue, pricingLocationID, pageNumber],
  );

  const [selectedProductIDs, setSelectedProductIDs] = useState<string[]>([]);
  const [refetchType, setRefetchType] = useState(RefetchType.Unknown);

  useEffect(() => {
    setSelectedProductIDs([]);
    setRefetchType(RefetchType.Unknown);
  }, [sellerID, queryStartDate, searchValue, pricingLocationID, pageNumber]);

  const variablesNew = useMemo(
    () => ({
      ...variables,
      paginationOption: {
        ...variables.paginationOption,
        ids: selectedProductIDs,
        type: refetchType,
      },
    }),
    [variables, selectedProductIDs, refetchType],
  );

  const [productsResult, refetchProducts] = useProductsQuery({
    variables: { input: { ...variablesNew } },
    requestPolicy: 'network-only',
    pause: isProductsQueryPaused,
  });

  const resultProducts = (productsResult?.data as ProductsQuery)?.products.products;

  const {
    products: data,
    set: setData,
    stitching,
  } = useStitchProducts(resultProducts as ProductSection, refetchType === RefetchType.Unknown);

  const productTypes = data?.sections;
  const isEmpty = (data?.sections?.length ?? 0) === 0;

  const [productsMeta, refetchProductsMeta] = useProductsMetaQuery({
    variables: { input: { ...variablesNew } },
    requestPolicy: 'network-only',
    pause: isProductsQueryPaused,
  });

  const resultProductsMeta = productsMeta?.data?.products;
  const productsMetaData = useMemo(() => resultProductsMeta as ProductsScreen, [resultProductsMeta]);

  const fetching = productsMeta.fetching || productsResult.fetching || stitching;
  const error = productsMeta.error || productsResult.error;

  const [, saveSubmitPrice] = useSubmitPriceMutation();
  const [, saveSubmitSupply] = useSubmitSupplyOnDateMutation();

  const dates = productTypes?.[0]?.productWeights[0]?.products[0]?.supply.dates;

  const [dateIndex, setDateIndex] = useState(0);
  const prices = productTypes?.[0]?.productWeights[0]?.products[0]?.prices;

  const isAvailableCases = isInputsProfile || isVillaProfile;

  const handleRefetch = useCallback(
    (requestPolicy: RequestPolicy = 'network-only', ids: string[] = [], type = RefetchType.Unknown) => {
      if (refetchType !== type || selectedProductIDs[0] !== ids[0]) {
        setRefetchType(type);
        setSelectedProductIDs(ids);
      }
      refetchProducts({ requestPolicy });
      refetchProductsMeta({ requestPolicy });
      setUpdateDirty(false);
    },
    [refetchProducts, refetchProductsMeta, refetchType, selectedProductIDs],
  );

  const handleSavePrice = useCallback(
    async (submitPrice: SubmitPrice) => {
      if (!sellerID) return;
      if (Number.isNaN(submitPrice.amount)) {
        submitPrice.amount = 0;
      }
      const body = {
        sellerID,
        sku: submitPrice.sku,
        location: submitPrice.location,
        amount: submitPrice.amount,
      };
      const result = await saveSubmitPrice(body);
      if (!result.error) {
        setData((draft) => {
          const location = productsMetaData?.locationOptions.find((location) => location.label === body.location);
          if (location) {
            draft?.sections?.forEach((productType) => {
              productType.productWeights.forEach((productWeight) => {
                productWeight.products.forEach((product) => {
                  if (product.sku === body.sku) {
                    product.prices.forEach((price) => {
                      if (location.value === price.locationID.toString()) {
                        price.amount = body.amount;
                      }
                    });
                  }
                });
              });
            });
          }
        });
        setUpdateDirty(true);
      }
      return result.error;
    },
    [saveSubmitPrice, sellerID, setData, productsMetaData?.locationOptions],
  );

  const updateSupply = (
    draft: any,
    body: {
      sellerID: string;
      productID: string;
      date: string;
      maxCases: number;
    },
    csvDate: string,
    isAvailableCases: boolean,
  ) => {
    const findProduct = (product: ProductData) => product.productID === body.productID;
    const findDate = (date: DeliveryDate) => date.csvDate === csvDate;

    for (const productType of draft?.sections ?? []) {
      for (const productWeight of productType.productWeights) {
        for (const product of productWeight.products) {
          if (findProduct(product)) {
            const supply = product.supply;
            const dateToUpdate = supply.dates.find(findDate);

            if (dateToUpdate) {
              dateToUpdate.maxCases = body.maxCases;
              break;
            }

            break;
          }
        }
      }
    }

    draft?.sections?.forEach((productType: ProductTypeSection) => {
      productType.productWeights.forEach((productWeight) => {
        productWeight.products.forEach((product) => {
          if (findProduct(product)) {
            const supply = product.supply;
            const dateToUpdate = supply.dates.find(findDate);

            if (dateToUpdate) {
              if (isAvailableCases) {
                dateToUpdate.availableCases += body.maxCases;
              } else {
                dateToUpdate.maxCases = body.maxCases;
              }
            }
          }
        });
      });
    });

    return draft;
  };

  const handleSubmitSupply = useCallback(
    async (submitSupply: SubmitSupply) => {
      if (!sellerID) return;
      const csvDate = submitSupply.deliveryDate.csvDate;
      const date = dayjs.utc(csvDate, 'DD-MMM-YYYY').toISOString();
      const body = {
        sellerID,
        productID: submitSupply.productID,
        date: date,
        maxCases: submitSupply.maxCases,
      };
      const result = await saveSubmitSupply(body);
      if (!result.error) {
        setData((draft) => updateSupply(draft, body, csvDate, isAvailableCases));
        setUpdateDirty(true);
      }
      return result.error;
    },
    [saveSubmitSupply, sellerID, setData, isAvailableCases],
  );

  useEffect(() => {
    if (!pricingLocationID && data) {
      setPricingLocationID(productsMetaData?.locationOptions[0].value ?? DEFAULT_LOCATION_ID);
    }
  }, [pricingLocationID, data, productsMetaData?.locationOptions]);

  useEffect(() => {
    if (!fetching && dateIndex < 0) {
      setDateIndex(dateIndex === -1 ? 0 : requestDays - screenSizeDays);
    }
  }, [fetching, dateIndex, requestDays, screenSizeDays]);

  const handleResetPage = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPageNumber(DEFAULT_PAGE_NUMBER);
  }, []);

  useEffect(() => {
    handleResetPage();
  }, [sellerID, handleResetPage]);

  const handleStartDateChange = useCallback(
    (day: Dayjs) => {
      if (!dates) {
        return;
      }
      const noOfDays = day.diff(startDate, 'days');
      const newIndex = dateIndex + noOfDays;
      if (newIndex >= requestDays - screenSizeDays) {
        const date = dayjs(dates?.[newIndex]?.csvDate);
        setDateIndex(-1);
        setStartDate(date);
        handleResetPage();
      } else if (newIndex < 0) {
        const date = startDate.subtract(requestDays, 'day');
        setDateIndex(-2);
        setStartDate(date);
        handleResetPage();
      } else {
        setDateIndex(newIndex);
      }
    },
    [dates, requestDays, screenSizeDays, dateIndex, startDate, handleResetPage],
  );

  const rangeStepperOptionsRef = useRef<{ title: string; subtitle: string }[]>([]);

  const rangeStepperOptions = useMemo(() => {
    if (dateIndex >= 0) {
      rangeStepperOptionsRef.current =
        dates?.slice(dateIndex, dateIndex + screenSizeDays).map((current) => {
          const date = dayjs(current.csvDate);
          const today = dayjs();
          const isToday = date.isSame(today, 'date');
          return {
            title: isToday ? 'Today' : isMobile ? date.format('ddd D MMM') : date.format('D MMM'),
            subtitle: isToday || isMobile ? '' : date.format('dddd'),
          };
        }) ?? [];
    }

    return rangeStepperOptionsRef.current;
  }, [dates, screenSizeDays, isMobile, dateIndex]);

  const rangeStepperOptionsInputs = useMemo(() => {
    if (!dates || !dates[0]) return [];
    const date = dayjs(dates[0].csvDate);

    const today = dayjs();
    const isToday = date.isSame(today, 'date');

    const thisWeekMonday = today.subtract(getDayOfWeek(today), 'day');
    const isThisWeekMonday = thisWeekMonday.isSame(date, 'date');

    const isThisWeek = isToday || isThisWeekMonday;
    return [
      {
        title: isThisWeek ? 'This week' : `Week of ${date.format('ddd D MMM')}`,
        subtitle: '',
      },
    ];
  }, [dates]);

  const pricesBarOptions = useMemo(() => prices?.map((price) => price.code) ?? [], [prices]);

  const totalProducts =
    productTypes?.reduce((acc, productType) => {
      return acc + (productType?.productWeights?.[0]?.products?.length ?? 0);
    }, 0) ?? 0;

  const value = useMemo(
    () => ({
      isFetching: fetching,
      isNoData: !fetching && !data && !error,
      error,
      dateIndex: dateIndex >= 0 ? dateIndex : dateIndex === -1 ? 0 : requestDays - screenSizeDays - 1,
      info: productsMetaData?.info,
      noticeDialog: productsMetaData?.noticeDialog,
      locationOptions: productsMetaData?.locationOptions,
      weekdays: productsMetaData?.weekdays,
      suggestions: data?.suggestions,
      pageInfo: data?.pageInfo,
      productTypes: data?.sections,
      sellerID,
      pricingLocationID,
      rangeStepperOptions,
      rangeStepperOptionsInputs,
      pricesBarOptions,
      screenSizeDays,
      searchValue,
      startDate,
      isUpdateDirty,
      isEmpty,
      totalProducts,
      onDataChange: setData,
      onSearchChange: (val: string) => {
        handleResetPage();
        setSearchValue(val);
      },
      onStartDateChange: handleStartDateChange,
      onPricingLocationIDChange: (val: string) => {
        handleResetPage();
        setPricingLocationID(val);
      },
      onRefetch: handleRefetch,
      onSubmitPrice: handleSavePrice,
      onSubmitSupply: handleSubmitSupply,
      onResetPage: handleResetPage,
      onIncreasePage: () => {
        setPageNumber((prev) => prev + 1);
      },
    }),
    [
      handleRefetch,
      handleSavePrice,
      handleSubmitSupply,
      setSearchValue,
      setPricingLocationID,
      pricingLocationID,
      setData,
      error,
      dateIndex,
      fetching,
      rangeStepperOptions,
      rangeStepperOptionsInputs,
      pricesBarOptions,
      screenSizeDays,
      searchValue,
      startDate,
      sellerID,
      isUpdateDirty,
      isEmpty,
      totalProducts,
      productsMetaData,
      data,
      handleStartDateChange,
      requestDays,
      handleResetPage,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useProducts = () => {
  const result = useContext(Context);
  if (!result) {
    throw new Error('useProducts must be used within ProductsProvider');
  }
  return result;
};
