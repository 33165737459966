import { Box, DialogContent, IconButton, Stack } from '@mui/material';
import { MdClose } from 'react-icons/md';

const ImageUploadActiveImage = ({
  activeImage,
  setActiveImage,
}: {
  activeImage: string;
  setActiveImage: (activeImage: string) => void;
}) => {
  return (
    <DialogContent>
      <Stack direction={'row'} gap={1} sx={{ position: 'relative' }}>
        <Box
          component="img"
          src={activeImage}
          loading="eager"
          sx={(theme) => ({
            width: '100%',
            maxHeight: 400,
            objectFit: 'contain',
            borderRadius: theme.spacing(1),
            background: theme.palette.grey[200],
          })}
        />
        <IconButton
          sx={(theme) => ({
            height: 'fit-content',
            position: 'absolute',
            color: theme.palette.common.white,
            background: theme.palette.primary.light,
            left: '90%',
            top: '2%',
          })}
          onClick={() => setActiveImage('')}
        >
          <MdClose size={14} />
        </IconButton>
      </Stack>
    </DialogContent>
  );
};

export default ImageUploadActiveImage;
