import { Stack, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import useIsViewport from 'hooks/useIsViewport';
import { TableAction, TableColumn, TableType } from 'generated/graphql';
import ExpandableTableCell from './ExpandableTableCell';
import ExpandableTableColumnTags from './ExpandableTableColumnTags';
import { getTableColumnStyle } from './ExpandableTableStyles';

const ExpandableTableHead = ({
  type = TableType.Standard,
  columns = [],
  tableActions = [],
  onTagClick,
}: {
  type: TableType;
  columns: TableColumn[];
  tableActions: TableAction[];
  onTagClick: (url: string) => void;
}) => {
  const theme = useTheme();
  const isMobile = useIsViewport();

  const isStatisticalType = type === TableType.Statistics;
  const isReportingType = type === TableType.Reporting;
  const isBasicType = type === TableType.Basic;

  const showTableColumns = (isStatisticalType || isBasicType || !isMobile) && !isReportingType;
  const showRowSelectionCheckboxes = !!tableActions?.some((action) => action.minRequiredRowSelection >= 1);

  if (!showTableColumns) return null;

  return (
    <TableHead
      sx={{
        boxShadow: isBasicType ? '1px 0px 5px rgba(0,0,0,0.4)' : 'none',
        zIndex: isBasicType ? 5 : 0,
        position: isBasicType ? 'relative' : 'static',
      }}
    >
      <TableRow
        sx={(theme) => ({
          overflow: 'none',
          background: isBasicType ? theme.palette.tertiary.light : theme.palette.background.paper,
        })}
      >
        {columns.map((column, i) => (
          <ExpandableTableCell
            key={+i}
            sx={{ ...getTableColumnStyle(type, i, column.align) }}
            align={column.align.toLowerCase() as 'left' | 'right'}
          >
            <Stack alignItems={column.align === 'Right' ? 'flex-end' : 'flex-start'}>
              <Typography variant="subtitle2">{column.value}</Typography>
              <Typography variant="subtitle2" sx={(theme) => ({ fontWeight: theme.typography.fontWeightRegular })}>
                {column.subTitle}
              </Typography>
              {isStatisticalType && <ExpandableTableColumnTags column={column} onTagClick={onTagClick} />}
            </Stack>
          </ExpandableTableCell>
        ))}
        {/* TODO: Check if this is required */}
        {showRowSelectionCheckboxes && <TableCell width={theme.spacing(1)} />}
      </TableRow>
    </TableHead>
  );
};

export default ExpandableTableHead;
